import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'; 
import AsideAdmin from './AsideAdmin';
//import Navbar from '../Navbar';
import Footer from '../Footer';
import DashboardAdmin from './DashboardAdmin'; 
import NavAdmin from '../NavAdmin';
import AdminUserCreation from './AdminUserCreation';
import Documentation from './Documentation';
import Onboarding from './Onboarding';

const DashboardAdm = () => {
  return (
    <div className="App">
      <AsideAdmin />
      <main className="dashboard-main">
        <NavAdmin />
        <Routes>
          {/* Define las rutas disponibles */}
          <Route path="/" element={<DashboardAdmin />} />
          <Route path="/create-user" element={<AdminUserCreation />} />
          <Route path="/documentation" element={<Documentation />}/>
          <Route path="/onboarding" element={<Onboarding />}/>
          <Route path="*" element={<Navigate to="/" />} /> {/* Redirección a la raíz */}
        </Routes>
        <Footer />
      </main>
    </div>
  );
};

export default DashboardAdm;
