// Footer.js
import React from 'react';

const Footer = () => (
  <footer className="d-footer py-3 footer-custom-bg text-white" style={{
    backgroundColor: '#2a2a2a'
    
  }}>
    <div className="container">
      <div className="row align-items-center justify-content-between">
        <div className="col-auto">
          <p className="mb-0">© OTC 2024.</p>
        </div>
        <div className="col-auto">
          <p className="mb-0">
            2024 <span className="text-primary-600 fw-semibold">.</span>
          </p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
