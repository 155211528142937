import React, { useState, useEffect, createContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/User/Login';
import LoginAdmin from './components/Admin/LoginAdmin';
import Dashboard from './components/User/Dashboard';
import DashboardAdm from './components/Admin/DashboardAdm';
import Deposito from './components/User/Deposito';
import Transferencia from './components/User/Transferencia';
import Home from './components/User/Home';

export const AuthContext = createContext();

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(() => !!sessionStorage.getItem('token'));
  const [user, setUser] = useState(() => JSON.parse(sessionStorage.getItem('user')) || null);
  const [userType, setUserType] = useState(() => sessionStorage.getItem('userType') || '');

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const userData = JSON.parse(sessionStorage.getItem('user'));
    const type = sessionStorage.getItem('userType');

    if (token && userData) {
      setUser(userData);
      setIsAuthenticated(true);
      setUserType(type);
    } else {
      setIsAuthenticated(false);
      setUserType('');
    }
  }, []);

  const loginUser = (token, userData, type) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user', JSON.stringify(userData));
    sessionStorage.setItem('userType', type);

    setUser(userData);
    setIsAuthenticated(true);
    setUserType(type);
  };

  const logoutUser = () => {
    sessionStorage.clear();
    setUser(null);
    setIsAuthenticated(false);
    setUserType('');
  };

  const PrivateRoute = ({ children, role }) => {
    if (!isAuthenticated) {
      return <Navigate to={role === 'admin' ? '/admin-login' : '/login'} />;
    }
    if (userType !== role) {
      return <Navigate to="/" />;
    }
    return children;
  };

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, userType, loginUser, logoutUser }}>
      <Router>
        <Routes>
          {/* Ruta inicial */}
          <Route path="/" element={isAuthenticated ? (userType === 'admin' ? <DashboardAdm /> : <Dashboard />) : <Home />} />
          
          {/* Rutas de login */}
          <Route path="/login" element={<Login />} />
          <Route path="/admin-login" element={<LoginAdmin />} />
          
          {/* Rutas protegidas para usuarios */}
          <Route
            path="/deposito"
            element={
              <PrivateRoute role="user">
                <Deposito />
              </PrivateRoute>
            }
          />
          <Route
            path="/transferencia"
            element={
              <PrivateRoute role="user">
                <Transferencia />
              </PrivateRoute>
            }
          />

          {/* Rutas protegidas para administradores */}
          <Route
            path="/admin/*"
            element={
              <PrivateRoute role="admin">
                <DashboardAdm />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
