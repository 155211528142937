import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';

const Documentation = () => {
  const [users, setUsers] = useState([]);
  const [userDocuments, setUserDocuments] = useState({});
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = 5;
  const apiUrl = 'https://api.bitfin.co:8081';
  const userToken = sessionStorage.getItem('token');

  // Cambiar el endpoint a getAllUsers y solicitar solo username y email
  const fetchUsersWithDocuments = async () => {
    try {
      const response = await axios.get(`${apiUrl}/getAllUsers`, {
        params: { user_identifier: 'john_doe' },
        headers: { Authorization: `Bearer ${userToken}` },
      });
      // Extraer solo `username` y `email`
      const usersData = response.data.data.map(({ username, email }) => ({ username, email }));
      setUsers(usersData);
      await fetchDocumentsForUsers(usersData);
    } catch (err) {
      console.error('Error al obtener los usuarios:', err);
      setError('No se pudo cargar la lista de usuarios.');
    }
  };

  const fetchDocumentsForUsers = async (usersList) => {
    const documentsMap = {};
    for (const user of usersList) {
      try {
        const response = await axios.get(`${apiUrl}/listAllFilesForUserByCategory`, {
          params: {
            user_identifier: 'john_doe',
            file_user: user.username,
            file_category: 'client_documentation',
          },
          headers: { Authorization: `Bearer ${userToken}` },
        });
        documentsMap[user.username] = response.data.data || [];
      } catch (err) {
        console.error(`Error al obtener los documentos para ${user.username}:`, err);
        documentsMap[user.username] = [];
      }
    }
    setUserDocuments(documentsMap);
  };

  useEffect(() => {
    fetchUsersWithDocuments();
  }, []);

  const handleDownload = async (user, fileName) => {
    try {
      const response = await axios.get(`${apiUrl}/getDocumentation`, {
        params: {
          user_identifier: 'john_doe',
          file_user: user.username,
          file_category: 'client_documentation',
          file_name: fileName,
        },
        headers: { Authorization: `Bearer ${userToken}` },
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName === 'all' ? `${user.username}_documents.zip` : `${fileName}.pdf`}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error('Error al descargar el documento:', err);
      setError('No se pudo descargar el documento.');
    }
  };

  const pageCount = Math.ceil(users.length / itemsPerPage);
  const handlePageClick = ({ selected }) => setCurrentPage(selected);
  const paginatedUsers = users.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  // Dividir documentos en subgrupos de 5
  const splitIntoChunks = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  return (
    <div className="dashboard-main-body">
      <div className="row gy-4">
        <div className="col-lg-12">
          <div className="card h-100 p-0 radius-12">
            <div className="card-header bg-base py-16 px-24">
              <h6 className="mb-0">Documentación Usuarios</h6>
            </div>
            <div className="card-body p-24">
              <div className="table-responsive">
                <table className="table bordered-table rounded-table sm-table mb-0">
                  <thead>
                    <tr>
                      <th>Usuario</th>
                      <th>Email</th>
                      <th>Documentos</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedUsers.map((user) => (
                      <tr key={user.username}>
                        <td>{user.username}</td>
                        <td>{user.email}</td>
                        <td>
                          <div className="d-flex flex-column gap-2" style={{ maxWidth: '350px' }}>
                            {splitIntoChunks(userDocuments[user.username] || [], 5).map((chunk, index) => (
                              <div key={index} className="d-flex gap-2">
                                {chunk.map((doc, docIndex) => (
                                  <span
                                    key={docIndex}
                                    className="badge bg-secondary text-white rounded-pill text-truncate"
                                    onClick={() => handleDownload(user, doc)}
                                    style={{ cursor: 'pointer', minWidth: '80px', maxWidth: '80px' }}
                                    title={doc}
                                  >
                                    {doc.slice(0, 8)}...
                                  </span>
                                ))}
                              </div>
                            ))}
                          </div>
                        </td>
                        <td>
  <div className="d-flex flex-column gap-2">
    <button
      className="btn btn-sm btn-outline-secondary"
      onClick={() => handleDownload(user, 'all')}
      style={{ fontSize: '0.8rem', padding: '5px 10px' }} // Estilo adicional para reducir tamaño
    >
      Descargar Todo
    </button>
  </div>
</td>

                      </tr>
                    ))}
                  </tbody>
                </table>
                <ReactPaginate
                  previousLabel={"Anterior"}
                  nextLabel={"Siguiente"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination dark-pagination justify-content-center mt-3"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link dark-page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link dark-page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link dark-page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {error && <p className="text-danger mt-3">{error}</p>}
    </div>
  );
};

export default Documentation;
