import React from 'react';
import Aside from '../Aside';
import Navbar from '../Navbar';
import DashboardMainBody from './DashboardMainBody';
import Footer from '../Footer';

const Dashboard = () => {
  return (
    <div className="App">
      <Aside />
      <main className="dashboard-main">
        <Navbar />
        <DashboardMainBody />
        <Footer />
      </main>
    </div>
  );
};

export default Dashboard;
