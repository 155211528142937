import React, { useContext, useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { AuthContext } from '../App';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const { logoutUser } = useContext(AuthContext);
  const navigate = useNavigate();

  // Estado para el menú hamburguesa
  const [menuOpen, setMenuOpen] = useState(false);

  // Obtener el nombre del usuario desde sessionStorage
  const userName = JSON.parse(sessionStorage.getItem('user'))?.name || 'Usuario';

  // Función para alternar el menú hamburguesa
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Función para manejar el cierre de sesión
  const handleLogout = () => {
    logoutUser();
    navigate('/login');
  };

  return (
    <div className="navbar-header">
      {/* Logo y menú hamburguesa */}
      <div className="row align-items-center justify-content-between">
        <div className="col-auto d-flex align-items-center gap-3">
          <img src="/assets/images/logo.png" alt="Logo" style={{ height: '40px' }} />
          <button
            className="hamburger-icon d-lg-none"
            onClick={toggleMenu}
            aria-label="Abrir Menú"
          >
            <Icon icon={menuOpen ? 'mdi:close' : 'mdi:menu'} fontSize="24px" />
          </button>
        </div>

        {/* Menú de escritorio */}
        <div className="col-auto d-none d-lg-flex">
          <div className="navbar-links d-flex align-items-center gap-3">
       
          </div>
          <div className="d-flex align-items-center gap-3">
            <button className="btn btn-dark me-2" onClick={handleLogout}>
              Cerrar Sesión
            </button>
            <div className="dropdown">
              <button
                className="d-flex justify-content-center align-items-center rounded-circle"
                type="button"
                data-bs-toggle="dropdown"
              >
                <img
                  src="/assets/images/user.png"
                  alt="User"
                  className="w-40-px h-40-px object-fit-cover rounded-circle"
                />
              </button>
              <div className="dropdown-menu to-top dropdown-menu-sm">
                <div className="py-12 px-16 radius-8 bg-primary-50 mb-16 d-flex align-items-center justify-content-between gap-2">
                  <div>
                    <h6 className="text-lg text-primary-light fw-semibold mb-2">
                      Bienvenido:
                    </h6>
                    <span className="text-secondary-light fw-medium text-sm">
                      {userName}
                    </span>
                  </div>
                </div>
                <ul className="to-top-list">
                  <li>
                    <button
                      className="dropdown-item d-flex align-items-center gap-3 hover-text-danger"
                      onClick={handleLogout}
                    >
                      <Icon icon="mdi:logout" />
                      Log Out
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Menú hamburguesa para móvil */}
      {menuOpen && (
        <div className="mobile-menu d-lg-none">
          <a href="/">Dashboard</a>
          <a href="/deposito">Depósito</a>
          <a href="/transferencia">Retiro</a>
          <button className="btn btn-dark mt-2 w-100" onClick={handleLogout}>
            Cerrar Sesión
          </button>
        </div>
      )}
    </div>
  );
};

export default Navbar;
