import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import WalletCard from './WalletCard';
import Aside from '../Aside';
import Navbar from '../Navbar';
import Footer from '../Footer';

const Transferencia = () => {
  const navigate = useNavigate();
  const [monedas, setMonedas] = useState([]);
  const [redes, setRedes] = useState([]);
  const [selectedMoneda, setSelectedMoneda] = useState('');
  const [selectedRed, setSelectedRed] = useState('');
  const [codigoCuenta, setCodigoCuenta] = useState('');
  const [cantidad, setCantidad] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const apiUrl = 'https://api.bitfin.co:8081';
  const userToken = sessionStorage.getItem('token');
  const userIdentifier = JSON.parse(sessionStorage.getItem('user'))?.name || 'user_default';

  // Obtener lista de monedas
  useEffect(() => {
    const fetchMonedas = async () => {
      try {
        const response = await fetch(`${apiUrl}/getCurrencies`, {
          headers: { Authorization: `Bearer ${userToken}` },
        });
        const data = await response.json();
        setMonedas(data.data.currencies);
      } catch (err) {
        console.error('Error al cargar monedas:', err);
        setError('No se pudo cargar la lista de monedas.');
      }
    };
    fetchMonedas();
  }, [apiUrl, userToken]);

  // Manejar cambio de moneda
  const handleMonedaChange = async (e) => {
    const moneda = e.target.value;
    setSelectedMoneda(moneda);

    if (!moneda) {
      // Reiniciar las redes y la dirección si no hay moneda seleccionada
      setRedes([]);
      setSelectedRed('');
      setCodigoCuenta('');
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/getNetworksByCurrency?currency=${moneda}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      const data = await response.json();
      setRedes(data.data.networks);
    } catch (err) {
      console.error('Error al cargar redes:', err);
      setError('No se pudo cargar la lista de redes.');
    }
  };

  // Manejar cambio de red
  const handleRedChange = (e) => {
    const redCode = e.target.value;
    setSelectedRed(redCode);

    if (!redCode) {
      // Limpiar la dirección si no hay red seleccionada
      setCodigoCuenta('');
      return;
    }

    // Simular una llamada para obtener la dirección de cuenta
    try {
      const response = fetch(
        `${apiUrl}/getWalletByCurrencyAndNetwork?user_identifier=${encodeURIComponent(
          userIdentifier
        )}&network_code=${encodeURIComponent(redCode)}`,
        {
          headers: { Authorization: `Bearer ${userToken}`, 'Content-Type': 'application/json' },
        }
      );

      response
        .then((res) => res.json())
        .then((data) => {
          if (data?.data?.wallet_address) {
            setCodigoCuenta(data.data.wallet_address);
          } else {
            setCodigoCuenta('');
          }
        })
        .catch((err) => {
          console.error('Error al obtener la dirección:', err);
          setError('No se pudo obtener la dirección de la billetera.');
        });
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedMoneda || !selectedRed || !cantidad || !codigoCuenta) {
      setError('Todos los campos son obligatorios.');
      return;
    }

    const dataToSend = {
      destiny_address: codigoCuenta,
      amount: Number(cantidad),
      user_identifier: userIdentifier,
      currency_symbol: selectedMoneda,
      network_code: selectedRed,
    };

    try {
      const response = await fetch(`${apiUrl}/withdrawal`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        const responseData = await response.json();
        setSuccess(true);
        setModalContent(
          <div>
            <p className="text-success">Transferencia realizada con éxito.</p>
            <a href={responseData.data.explorer_url} target="_blank" rel="noopener noreferrer">
              Ver transacción en el explorador
            </a>
          </div>
        );
      } else {
        const errorData = await response.json();
        setModalContent(
          <p className="text-danger">{errorData.message || 'Error al realizar la transferencia'}</p>
        );
      }
    } catch (err) {
      console.error('Error al enviar la transacción:', err);
      setModalContent(<p className="text-danger">No se pudo completar la transferencia.</p>);
    }
  };

  return (
    <div className="App">
      <Aside />
      <main className="dashboard-main">
        <Navbar />
        <div className="dashboard-main-body">
          <div className="row gy-4">
            <div className="col-lg-9">
              <div className="card h-100 p-0 radius-12">
                <div className="card-header bg-base py-16 px-24">
                  <h6 className="mb-0">Formulario de Retiro</h6>
                </div>
                <div className="card-body p-24">
                  {error && <p className="text-danger">{error}</p>}
                  {success && <p className="text-success">Transferencia realizada con éxito.</p>}
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label className="form-label">Moneda</label>
                      <select
                        className="form-select"
                        value={selectedMoneda}
                        onChange={handleMonedaChange}
                        style={{
                          outline: 'none',
                          boxShadow: 'none',
                          userSelect: 'none',
                        }}
                      >
                        <option value="">Seleccione Moneda</option>
                        {monedas.map((moneda) => (
                          <option key={moneda.symbol} value={moneda.symbol}>
                            {moneda.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Red</label>
                      <select
                        className="form-select"
                        value={selectedRed}
                        onChange={handleRedChange}
                        style={{
                          outline: 'none',
                          boxShadow: 'none',
                          userSelect: 'none',
                        }}
                      >
                        <option value="">Seleccione Red</option>
                        {redes.map((red) => (
                          <option key={red.code} value={red.code}>
                            {red.symbol} - {red.code}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Dirección Cuenta</label>
                      <input
                        type="text"
                        className="form-control"
                        value={codigoCuenta}
                        readOnly
                        style={{
                          backgroundColor: '#1e1e1e',
                          color: '#fff',
                          border: '1px solid #ced4da',
                          outline: 'none',
                          boxShadow: 'none',
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Cantidad</label>
                      <input
                        type="number"
                        className="form-control"
                        value={cantidad}
                        onChange={(e) => setCantidad(e.target.value)}
                      />
                    </div>

                    <button type="submit" className="btn btn-dark w-100">
                      Confirmar Transferencia
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <WalletCard />
            </div>
          </div>
        </div>
        <Footer />

        {modalContent && (
          <div className="modal fade show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Resultado de la Transferencia</h5>
                  <button type="button" className="btn-close" onClick={() => setModalContent(null)}></button>
                </div>
                <div className="modal-body">{modalContent}</div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => setModalContent(null)}>
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Transferencia;
