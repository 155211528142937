import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';

const Documentation = () => {
  const [users, setUsers] = useState([]);
  const [userDocuments, setUserDocuments] = useState({});
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [onboardingError, setOnboardingError] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Estado para el loading

  const itemsPerPage = 5;
  const apiUrl = 'https://api.bitfin.co:8081';
  const userToken = sessionStorage.getItem('token');

  const fetchUsersWithDocuments = async () => {
    try {
      const response = await axios.get(`${apiUrl}/getPendingOnboardings`, {
        params: { user_identifier: 'john_doe' },
        headers: { Authorization: `Bearer ${userToken}` },
      });
      setUsers(response.data.data);
      await fetchDocumentsForUsers(response.data.data);
    } catch (err) {
      console.error('Error al obtener los usuarios:', err);
      setError('No se pudo cargar la lista de usuarios.');
    }
  };

  const fetchDocumentsForUsers = async (usersList) => {
    const documentsMap = {};
    for (const user of usersList) {
      try {
        const response = await axios.get(`${apiUrl}/listAllFilesForUserByCategory`, {
          params: {
            user_identifier: 'john_doe',
            file_user: user.company_name,
            file_category: 'onboarding',
          },
          headers: { Authorization: `Bearer ${userToken}` },
        });
        documentsMap[user.company_name] = response.data.data || [];
      } catch (err) {
        console.error(`Error al obtener los documentos para ${user.company_name}:`, err);
        documentsMap[user.company_name] = [];
      }
    }
    setUserDocuments(documentsMap);
  };

  useEffect(() => {
    fetchUsersWithDocuments();
  }, []);

  const handleDownload = async (user, fileName) => {
    try {
      const response = await axios.get(`${apiUrl}/getDocumentation`, {
        params: {
          user_identifier: 'john_doe',
          file_user: user.company_name,
          file_category: 'onboarding',
          file_name: fileName,
        },
        headers: { Authorization: `Bearer ${userToken}` },
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName === 'all' ? `${user.company_name}_documents.zip` : `${fileName}.pdf`}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error('Error al descargar el documento:', err);
      setError('No se pudo descargar el documento.');
    }
  };

  const handleOnboardUser = async () => {
    if (!acceptedTerms) {
      setOnboardingError('Debes aceptar los términos y condiciones para continuar.');
      return;
    }

    setIsLoading(true); // Inicia el loading

    try {
      await axios.post(
        `${apiUrl}/onboardCompany`,
        {
          user_identifier: 'john_doe',
          email: selectedUser.email,
          company_name: selectedUser.company_name,
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      setOnboardingError(null);
      setShowSuccessMessage(true);
      setShowConfirmModal(false);
      setAcceptedTerms(false);
      setIsLoading(false); // Termina el loading
      fetchUsersWithDocuments(); // Actualiza la lista de usuarios
      setTimeout(() => setShowSuccessMessage(false), 3000);
    } catch (err) {
      console.error('Error al hacer onboarding del usuario:', err);
      setOnboardingError('No se pudo realizar el onboarding del usuario.');
      setIsLoading(false); // Termina el loading en caso de error
    }
  };

  const openConfirmModal = (user) => {
    setSelectedUser(user);
    setShowConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
    setSelectedUser(null);
    setAcceptedTerms(false);
    setOnboardingError(null);
    setIsLoading(false);
  };

  const pageCount = Math.ceil(users.length / itemsPerPage);
  const handlePageClick = ({ selected }) => setCurrentPage(selected);
  const paginatedUsers = users.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  const splitIntoChunks = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  return (
    <div className="dashboard-main-body">
      <div className="row gy-4">
        <div className="col-lg-12">
          <div className="card h-100 p-0 radius-12">
            <div className="card-header bg-base py-16 px-24">
              <h6 className="mb-0">Usuarios Onboarding</h6>
            </div>
            <div className="card-body p-24">
              {users.length === 0 ? (
                <p className="text-center">No hay usuarios pendientes de onboarding.</p>
              ) : (
                <div className="table-responsive">
                  <table className="table bordered-table rounded-table sm-table mb-0">
                    <thead>
                      <tr>
                        <th>Empresa</th>
                        <th>Email</th>
                        <th>Documentos</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedUsers.map((user) => (
                        <tr key={user.company_name}>
                          <td>{user.company_name}</td>
                          <td>{user.email}</td>
                          <td>
                            <div className="d-flex flex-column gap-2" style={{ maxWidth: '350px' }}>
                              {splitIntoChunks(userDocuments[user.company_name] || [], 5).map((chunk, index) => (
                                <div key={index} className="d-flex gap-2">
                                  {chunk.map((doc, docIndex) => (
                                    <span
                                      key={docIndex}
                                      className="badge bg-secondary text-white rounded-pill text-truncate"
                                      onClick={() => handleDownload(user, doc)}
                                      style={{ cursor: 'pointer', minWidth: '80px', maxWidth: '80px' }}
                                      title={doc}
                                    >
                                      {doc.slice(0, 8)}...
                                    </span>
                                  ))}
                                </div>
                              ))}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex gap-2">
                              <button
                                className="btn btn-dark btn-sm"
                                onClick={() => openConfirmModal(user)}
                              >
                                Onboard
                              </button>
                              <button
                                className="btn btn-secondary btn-sm"
                                onClick={() => handleDownload(user, 'all')}
                              >
                                Descargar Todo
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <ReactPaginate
                    previousLabel={"Anterior"}
                    nextLabel={"Siguiente"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination dark-pagination justify-content-center mt-3"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link dark-page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link dark-page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link dark-page-link"}
                    activeClassName={"active"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {error && <p className="text-danger mt-3">{error}</p>}
      {onboardingError && <p className="text-danger mt-3">{onboardingError}</p>}
      {showSuccessMessage && <p className="text-success mt-3">Onboarding realizado exitosamente.</p>}

      {/* Modal de confirmación */}
      <Modal show={showConfirmModal} onHide={closeConfirmModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación de Onboarding</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Estás seguro de que deseas hacer onboarding al usuario <b>{selectedUser?.company_name}</b>?</p>
          <Form.Check 
            type="checkbox"
            label="Acepto los términos y condiciones"
            checked={acceptedTerms}
            onChange={(e) => setAcceptedTerms(e.target.checked)}
          />
          {isLoading && <p className="text-info mt-2">Onboarding en proceso, por favor espere...</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={closeConfirmModal} disabled={isLoading}>
            Cancelar
          </Button>
          <Button variant="dark" onClick={handleOnboardUser} disabled={!acceptedTerms || isLoading}>
            {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Confirmar'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Documentation;
