import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';

const DashboardAdmin = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [limites, setLimites] = useState({});
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = 5;
  const apiUrl = 'https://api.bitfin.co:8081';
  const userToken = sessionStorage.getItem('token');

  // Obtener todos los usuarios con sus límites
  const fetchUsuarios = async () => {
    try {
      const response = await axios.get(`${apiUrl}/getAllUsers?user_identifier=john_doe`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      setUsuarios(response.data.data);
    } catch (err) {
      console.error('Error al obtener los usuarios:', err);
      setError('No se pudo cargar la lista de usuarios.');
    }
  };

  useEffect(() => {
    fetchUsuarios();
  }, []);

  // Manejar la selección de un usuario
  const handleSeleccionUsuario = (usuario) => {
    setSelectedUser(usuario.username);
    setLimites({
      monthly_limit: usuario.monthly_limit,
      daily_limit: usuario.daily_limit,
      market_hour_limit: usuario.market_hour_limit,
      out_of_market_hour_limit: usuario.out_of_market_hour_limit,
      market_hour_price_basis_points: usuario.market_hour_price_basis_points,
      out_of_market_hour_price_basis_points: usuario.out_of_market_hour_price_basis_points,
    });
  };

  // Manejar los cambios en los límites
  const handleLimitChange = (key, value) => {
    setLimites((prevLimites) => ({
      ...prevLimites,
      [key]: parseFloat(value) || 0,
    }));
  };

  // Enviar cambios a la API
  const handleGuardarCambios = async () => {
    try {
      await axios.post(
        `${apiUrl}/updateLimits`,
        {
          user_identifier: 'john_doe',
          update_user: selectedUser,
          ...limites,
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      setSelectedUser(null);
      setLimites({});
      fetchUsuarios(); // Refrescar la lista de usuarios
    } catch (err) {
      console.error('Error al actualizar los límites:', err);
      setError('No se pudieron guardar los cambios.');
    }
  };

  // Paginación
  const pageCount = Math.ceil(usuarios.length / itemsPerPage);
  const handlePageClick = ({ selected }) => setCurrentPage(selected);
  const paginatedUsers = usuarios.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  return (
    <div className="dashboard-main-body">
      <h6 className="fw-semibold mb-0"><p>Límites Usuarios</p></h6>

      {/* Modificar Límites */}
      <div className="card mb-4 p-3 radius-12">
        <h6 className="mb-3">Modificar Límites</h6>
        {selectedUser ? (
          <div className="row">
            {Object.keys(limites).map((key) => (
              <div key={key} className="col-lg-4 mb-3">
                <label className="form-label">{key.replaceAll('_', ' ')}</label>
                <input
                  type="number"
                  className="form-control"
                  value={limites[key]}
                  onChange={(e) => handleLimitChange(key, e.target.value)}
                />
              </div>
            ))}
            <div className="col-lg-12">
              <button className="btn btn-dark w-100" onClick={handleGuardarCambios}>
                Guardar Cambios
              </button>
            </div>
          </div>
        ) : (
          <p>Selecciona un usuario para modificar sus límites.</p>
        )}
      </div>

      {/* Listado de Usuarios */}
      <div className="row gy-4">
        <div className="col-lg-12">
          <div className="card h-100 p-0 radius-12">
            <div className="card-header bg-base py-16 px-24">
              <h6 className="mb-0">Todos los Usuarios del Sistema</h6>
            </div>
            <div className="card-body p-24">
              <div className="table-responsive">
                <table className="table bordered-table rounded-table sm-table mb-0">
                  <thead>
                    <tr>
                      <th>Usuario</th>
                      <th>Mensual</th>
                      <th>Diario</th>
                      <th>Horario de Mercado</th>
                      <th>Fuera de Horario</th>
                      <th>Puntos en Mercado</th>
                      <th>Puntos Fuera Mercado</th>
                      <th>*</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedUsers.map((usuario) => (
                      <tr key={usuario.username}>
                        <td>{usuario.username}</td>
                        <td>{usuario.monthly_limit}</td>
                        <td>{usuario.daily_limit}</td>
                        <td>{usuario.market_hour_limit}</td>
                        <td>{usuario.out_of_market_hour_limit}</td>
                        <td>{usuario.market_hour_price_basis_points}</td>
                        <td>{usuario.out_of_market_hour_price_basis_points}</td>
                        <td>
                          <button
                            className="btn btn-dark btn-sm"
                            onClick={() => handleSeleccionUsuario(usuario)}
                          >
                            Modificar
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <ReactPaginate
                  previousLabel={"Anterior"}
                  nextLabel={"Siguiente"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination dark-pagination justify-content-center mt-3"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link dark-page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link dark-page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link dark-page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {error && <p className="text-danger mt-3">{error}</p>}
    </div>
  );
};

export default DashboardAdmin;
