// src/components/Admin/Aside_Admin.js
import React from 'react';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

const AsideAdmin = () => (
  <aside className="sidebar">
    <button type="button" className="sidebar-close-btn">
      <Icon icon="radix-icons:cross-2" />
    </button>
    <div>
      <Link to="/" className="sidebar-logo">
      
   
        <img src="/assets/images/logo.png" alt="site logo"  />
       
      </Link>
    </div>
    <div className="sidebar-menu-area">
      <ul className="sidebar-menu" id="sidebar-menu">
        <li className="sidebar-menu-group-title">Mis Accesos</li>
        <li>
          <Link to="/admin">
            <Icon icon="mdi:bank-transfer-in" className="menu-icon" />
            <span>Dashboard</span>
          </Link>
        </li>
      
        <li>
          <Link to="/admin/onboarding">
            <Icon icon="mdi:file-document-outline" className="menu-icon" />
            <span>Onboardings</span>
          </Link>
        </li>
        <li>
          <Link to="/admin/documentation">
            <Icon icon="mdi:file-document-outline" className="menu-icon" />
            <span>Documentacion</span>
          </Link>
        </li>
      </ul>
    </div>
  </aside>
);

export default AsideAdmin;
