import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

const RegisterCompanyModal = ({ show, onHide }) => {
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [files, setFiles] = useState({});
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleFileChange = (e) => {
    const newFiles = { ...files, [e.target.name]: e.target.files[0] };
    if (e.target.files[0].type !== 'application/pdf') {
      setError('Solo se permiten archivos PDF.');
    } else {
      setError('');
      setFiles(newFiles);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!companyName || !email) {
      setError('Por favor, completa todos los campos.');
      return;
    }

    if (Object.keys(files).length < 10) {
      setError('Por favor, sube los 10 documentos requeridos.');
      return;
    }

    const formData = new FormData();
    formData.append('company_name', companyName);
    formData.append('email', email);

    // Agregar cada archivo con el nombre del campo esperado
    Object.keys(files).forEach((key) => {
      formData.append(key, files[key]);
    });

    try {
      const response = await fetch('https://api.bitfin.co:8081/uploadClientDocumentation', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error al enviar el formulario.');
      }

      const data = await response.json();

      if (data.status === 200) {
        setSuccessMessage('Formulario enviado correctamente.');
        setCompanyName('');
        setEmail('');
        setFiles({});
        setError('');

        // Ocultar el mensaje de éxito y cerrar el modal después de 3 segundos
        setTimeout(() => {
          setSuccessMessage('');
          onHide();
        }, 3000);
      } else {
        setError('Hubo un error al enviar los datos. Inténtelo nuevamente.');
      }
    } catch (error) {
      console.error('Error al enviar los datos:', error);
      setError('Hubo un error al enviar los datos. Inténtelo nuevamente.');
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Registrar Empresa</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Nombre de la Empresa</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese el nombre de la empresa"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              className="custom-input"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Ingrese el email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="custom-input"
            />
          </Form.Group>

          <Form.Label className="mt-3">Documentos (PDF)</Form.Label>
          <Row>
            {[
              'ID Representante', // legal_rep_id
              'CURP', // curp
              'Constancia Fiscal Representante', // legal_rep_fiscal_status
              'Comprobante Domicilio Representante', // legal_rep_house_proof
              'Acta Constitutiva', // constitutive_act
              'Poderes Legales', // legal_rep_powers
              'Registro Público', // public_registry
              'Constancia Fiscal Empresa', // company_fiscal_status
              'Comprobante Domicilio Empresa', // company_address_proof
              'Estado de Cuenta Empresa' // company_operations_funds_act
            ].map((field, index) => (
              <Col xs={12} md={6} className="mb-3" key={index}>
                <Form.Group>
                  <Form.Label style={{ fontSize: '0.65rem' }}>
                    {field.replace(/_/g, ' ').toUpperCase()}
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name={field}
                    onChange={handleFileChange}
                    className="form-control-sm custom-file-input"
                    accept=".pdf"
                    style={{
                      backgroundColor: 'transparent',
                      color: '#ddd',
                      border: '1px solid #4d4d4d',
                      borderRadius: '8px',
                      padding: '8px',
                      fontSize: '0.85rem',
                      transition: 'color 0.3s ease, border-color 0.3s ease'
                    }}
                  />
                </Form.Group>
              </Col>
            ))}
          </Row>

          {error && <p className="text-danger">{error}</p>}
          {successMessage && <p className="text-success">{successMessage}</p>}

          <Button variant="dark" type="submit" className="w-100 mt-3 custom-submit-button">
            Enviar Registro
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RegisterCompanyModal;
