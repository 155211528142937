import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import WalletCard from './WalletCard';

function DashboardMainBody() {
  const navigate = useNavigate();
  const [totalBalance, setTotalBalance] = useState(0);
  const [accounts, setAccounts] = useState([]);
  const [transactions, setTransactions] = useState({ ETH: [], TRON: [] });
  const [previousTransactions, setPreviousTransactions] = useState({ ETH: [], TRON: [] });
  const [showToast, setShowToast] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentType, setCurrentType] = useState('ETH');
  const transactionsPerPage = 5;
  const [error, setError] = useState(null);

  const userToken = sessionStorage.getItem('token');
  const userName = JSON.parse(sessionStorage.getItem('user'))?.name || 'Usuario';

  const urlBalances = `https://api.bitfin.co:8081/getUserBalances?user_identifier=${encodeURIComponent(userName)}`;
  const urlTransactions = `https://api.bitfin.co:8081/getUserTransactions?user_identifier=${encodeURIComponent(userName)}`;

  // Obtener balances
  const fetchBalances = async () => {
    try {
      const response = await fetch(urlBalances, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error al obtener los balances');
      }

      const data = await response.json();
      setAccounts(data.data);
    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
    }
  };

  // Obtener transacciones
  const fetchTransactions = async () => {
    try {
        const response = await fetch(urlTransactions, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userToken}`,
            },
        });

        if (!response.ok) {
            throw new Error('Error al obtener las transacciones');
        }

        const data = await response.json();

        const newTransactions = {
            ETH: data.data?.ETH || [],
            TRON: data.data?.TRON || [],
        };

        // Verificar si hay nuevas transacciones
        if (
            previousTransactions.ETH.length > 0 &&
            (
                JSON.stringify(previousTransactions.ETH) !== JSON.stringify(newTransactions.ETH) ||
                JSON.stringify(previousTransactions.TRON) !== JSON.stringify(newTransactions.TRON)
            )
        ) {
            setShowToast(true);

            // Ocultar el toast después de 3 segundos
            setTimeout(() => setShowToast(false), 3000);
        }

        // Actualizar el estado de las transacciones previas después de la comparación
        setPreviousTransactions(newTransactions);
        setTransactions(newTransactions);
    } catch (error) {
        console.error('Error:', error);
        setError(error.message);
    }
};

  // Actualizar datos
  const updateData = () => {
    fetchBalances();
    fetchTransactions();
  };

  useEffect(() => {
    updateData();
    const interval = setInterval(updateData, 10000); // Actualizar cada 10 segundos
    return () => clearInterval(interval);
  }, []);

  const calculateTotalBalance = () =>
    accounts.reduce((total, account) => total + account.balance, 0);

  useEffect(() => {
    setTotalBalance(calculateTotalBalance());
  }, [accounts]);

  const getNetworks = () =>
    accounts.map((account) => account.network).join(' - ');

  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = transactions[currentType].slice(indexOfFirstTransaction, indexOfLastTransaction);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const toggleTransactionType = () => {
    setCurrentType((prevType) => (prevType === 'ETH' ? 'TRON' : 'ETH'));
    setCurrentPage(1);
  };




  return (
    <div className="dashboard-main-body">
      <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mb-24">
        <h6 className="fw-semibold mb-0">Wallet</h6>
        <ul className="d-flex align-items-center gap-2">
          <li className="fw-medium">
            <a href="index.html" className="d-flex align-items-center gap-1 hover-text-primary">
              <i className="fas fa-home text-lg"></i> Dashboard
            </a>
          </li>
          <li>-</li>
          <li className="fw-medium">Wallet</li>
        </ul>
      </div>
  
      <div className="row">
        <div className="col-md-9">
          <div className="row mb-4">
            <div className="col-12">
              <div className="card p-0 radius-12">
                <div className="card-header bg-base py-16 px-24">
                  <h6 className="mb-0">Mi Información</h6>
                </div>
                <div className="card-body p-24">
                  <div className="row gy-4">
                    <div className="col-lg-4 col-md-6 mb-4">
                      <div className="card p-3 shadow-sm radius-8 border h-100">
                        <div>
                          <span><b>Total Billetera</b></span>
                          <h6>${totalBalance}</h6>
                          <p className="text-sm text-secondary">
                            {getNetworks()}
                          </p>
                        </div>
                      </div>
                    </div>

                    {accounts.map((account, index) => (
                      <div key={index} className="col-lg-4 col-md-6 mb-4">
                        <div className="card p-3 shadow-sm radius-8 border h-100">
                          <div className="d-flex flex-column justify-content-between h-100">
                            <div>
                              <span className="fw-bold d-block">Cuenta {account.network}</span>
                              <h6 className="mb-3">${account.balance}</h6>
                            </div>
                            <div>
                              <p className="text-sm text-secondary mb-0">Red: {account.network}</p>
                              <span style={{ fontSize: '10px', color: '#ffffff' }}>{account.address}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {error && <p className="text-danger">{error}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card p-3 radius-8">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h6 className="fw-semibold">Transacciones</h6>
                  <button onClick={toggleTransactionType} className="btn btn-dark">
                    Mostrar {currentType === 'ETH' ? 'TRON' : 'ETH'} Transacciones
                  </button>
                </div>
                <div className="table-responsive scroll-sm">
                  <table className="table bordered-table sm-table mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Red</th>
                        <th scope="col">Monto</th>
                        <th scope="col">Desde</th>
                        <th scope="col">Hasta</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Hash</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTransactions.map((tx, index) => (
                        <tr key={index}>
                          <td>
                            <a href={tx.explorer_url} target="_blank" rel="noopener noreferrer" className="d-flex align-items-center">
                              <img src={`assets/images/crypto/crypto-${currentType.toLowerCase()}.png`} alt={currentType} className="w-40-px h-40-px rounded-circle flex-shrink-0 me-12 overflow-hidden" />
                              <span className="flex-grow-1 d-flex flex-column">
                                <span className="text-md mb-0 fw-medium text-primary-light d-block">{currentType}</span>
                                <span className="text-xs mb-0 fw-normal text-secondary-light">{currentType === 'ETH' ? 'ETH' : 'TRX'}</span>
                              </span>
                            </a>
                          </td>
                          <td>${tx.amount}</td>
                          <td title={tx.from}>{tx.from.slice(0, 5)}...{tx.from.slice(-5)}</td>
                          <td title={tx.to}>{tx.to.slice(0, 5)}...{tx.to.slice(-5)}</td>
                          <td>
                            {tx.direction === "Deposit" ? (
                              <span className="bg-success-focus text-success-600 px-16 py-6 rounded-pill fw-semibold text-xs">
                                <i className="ri-arrow-up-s-fill"></i> Depósito
                              </span>
                            ) : (
                              <span className="bg-danger-focus text-danger-600 px-16 py-6 rounded-pill fw-semibold text-xs">
                                <i className="ri-arrow-down-s-fill"></i> Retiro
                              </span>
                            )}
                          </td>
                          <td title={tx.hash}>{tx.hash.slice(0, 5)}...{tx.hash.slice(-5)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <button
                    className="btn btn-dark"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Anterior
                  </button>
                  <span>Página {currentPage}</span>
                  <button
                    className="btn btn-dark"
                    onClick={handleNextPage}
                    disabled={indexOfLastTransaction >= transactions[currentType].length}
                  >
                    Siguiente
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <WalletCard />
        </div>
      </div>

      {/* Toast para nuevas transacciones */}
      {showToast && (
        <div
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            background: '#333',
            color: '#fff',
            padding: '10px 20px',
            borderRadius: '8px',
            zIndex: 1000,
          }}
        >
          <Icon icon="mdi:bell-ring" style={{ marginRight: '8px' }} />
          ¡Nueva transacción!
        </div>
      )}
    </div>
  );
}

export default DashboardMainBody;
