import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function WalletCard() {
  const navigate = useNavigate();
  const [totalBalance, setTotalBalance] = useState(0);
  const [limites, setLimites] = useState([]);
  const [error, setError] = useState(null);

  const userToken = sessionStorage.getItem('token');
  const userName = JSON.parse(sessionStorage.getItem('user'))?.name || 'Usuario';

  const apiUrlBalances = `https://api.bitfin.co:8081/getUserBalances?user_identifier=${encodeURIComponent(userName)}`;
  const apiUrlLimits = `https://api.bitfin.co:8081/getUserLimits?user_identifier=${encodeURIComponent(userName)}`;

  // Obtener el balance total
  const fetchTotalBalance = async () => {
    try {
      const response = await axios.get(apiUrlBalances, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (response.status !== 200) {
        throw new Error('Error al obtener los balances');
      }

      const data = response.data;
      const total = data.data.reduce((acc, account) => acc + account.balance, 0);
      setTotalBalance(total);
    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
    }
  };

  // Obtener los límites
  const fetchLimits = async () => {
    try {
      const response = await axios.get(apiUrlLimits, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (!response.status === 200) {
        throw new Error('Error al obtener los límites');
      }

      const data = response.data;
      setLimites([
        {
          name: 'Límite Mensual',
          value: `${data.data.current_monthly_allowance} de ${data.data.monthly_limit}`,
        },
        {
          name: 'Límite Diario',
          value: `${data.data.current_daily_allowance} de ${data.data.daily_limit}`,
        },
        {
          name: 'Límite Horario de Mercado',
          value: `${data.data.current_market_hour_allowance} de ${data.data.market_hour_limit}`,
        },
        {
          name: 'Límite Horario Fuera del Mercado',
          value: `${data.data.current_out_of_market_hour_allowance} de ${data.data.out_of_market_hour_limit}`,
        },
      ]);
    } catch (error) {
      console.error('Error:', error);
      setError('No se pudieron cargar los límites.');
    }
  };

  useEffect(() => {
    fetchTotalBalance();
    fetchLimits();
  }, []);

  return (
    <div className="card h-100">
    <div className="card-body p-0">
      <div className="px-24 py-20">
        <span className="mb-8">Mi Billetera</span>
        <h5 className="text-2xl">${totalBalance}</h5>
        <div className="mt-24 pb-24 mb-24 border-bottom d-flex align-items-center gap-16 justify-content-between flex-wrap">
          {/* Botón de Depósito */}
          <div
            className="text-center d-flex align-items-center flex-column"
            onClick={() => navigate('/deposito')}
            style={{ cursor: 'pointer' }} // Se asegura que el cursor cambie a mano
          >
            <span
              className="w-60-px h-60-px bg-primary-50 text-primary-600 text-2xl d-inline-flex justify-content-center align-items-center rounded-circle"
              style={{ cursor: 'pointer' }} // Se asegura que también dentro del ícono cambie a mano
            >
              <i className="ri-download-2-line"></i>
            </span>
            <span className="text-primary-light fw-medium mt-6">Deposito</span>
          </div>
  
          {/* Botón de Retiro */}
          <div
            className="text-center d-flex align-items-center flex-column"
            onClick={() => navigate('/transferencia')}
            style={{ cursor: 'pointer' }} // Se asegura que el cursor cambie a mano
          >
            <span
              className="w-60-px h-60-px bg-primary-50 text-primary-600 text-2xl d-inline-flex justify-content-center align-items-center rounded-circle"
              style={{ cursor: 'pointer' }} // Se asegura que también dentro del ícono cambie a mano
            >
              <i className="ri-upload-2-line"></i>
            </span>
            <span className="text-primary-light fw-medium mt-6">Retiro</span>
          </div>
        </div>
  
        <div className="d-flex align-items-center justify-content-between gap-8 pb-24 border-bottom">
          <h6 className="text-lg mb-0">Mis Limites</h6>
        </div>
  
        {/* Límites */}
        {limites.map((limite, index) => (
          <div
            key={index}
            className="d-flex align-items-center justify-content-between flex-wrap gap-8 py-16 border-bottom"
          >
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 d-flex flex-column">
                <span className="text-md mb-0 fw-medium text-primary-light d-block">{limite.name}</span>
                <span className="text-xs mb-0 fw-normal text-secondary-light">{limite.value}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    {error && <p className="text-danger mt-2">{error}</p>}
  </div>
  
  );
}

export default WalletCard;
