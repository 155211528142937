// Aside.js
import React from 'react';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

const Aside = () => (
  <aside className="sidebar">
    <button type="button" className="sidebar-close-btn">
      <Icon icon="radix-icons:cross-2" />
    </button>
    <div>
      <Link to="/" className="sidebar-logo">
        <img src="assets/images/logo.png" alt="site logo" className="light-logo" />
        <img src="assets/images/logo.png" alt="site logo" className="dark-logo" />
        <img src="assets/images/logo-icon.png" alt="site logo" className="logo-icon" />
      </Link>
    </div>
    <div className="sidebar-menu-area">
      <ul className="sidebar-menu" id="sidebar-menu">
        <li className="sidebar-menu-group-title">Mis Accesos</li>
        <li>
          <Link to="/">
            <Icon icon="mdi:bank-transfer-in" className="menu-icon" />
            <span>DashBoard</span>
          </Link>
        </li>
        <li>
          <Link to="/deposito">
            <Icon icon="mdi:bank-transfer-in" className="menu-icon" />
            <span>Depósito</span>
          </Link>
        </li>

        <li>
          <Link to="/transferencia">
            <Icon icon="mdi:bank-transfer" className="menu-icon" />
            <span>Retiro</span>
          </Link>
        </li>
      </ul>
    </div>
  </aside>
);

export default Aside;
