import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import WalletCard from './WalletCard';
import Aside from '../Aside';
import Navbar from '../Navbar';
import Footer from '../Footer';

const Deposito = () => {
  const navigate = useNavigate();
  const [monedas, setMonedas] = useState([]);
  const [redes, setRedes] = useState([]);
  const [selectedMoneda, setSelectedMoneda] = useState('');
  const [selectedRed, setSelectedRed] = useState('');
  const [codigoCuenta, setCodigoCuenta] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const apiUrl = 'https://api.bitfin.co:8081';
  const userToken = sessionStorage.getItem('token');
  const userIdentifier = JSON.parse(sessionStorage.getItem('user'))?.name || 'user_default';

  // Obtener lista de monedas
  useEffect(() => {
    const fetchMonedas = async () => {
      try {
        const response = await fetch(`${apiUrl}/getCurrencies`, {
          headers: { Authorization: `Bearer ${userToken}` },
        });
        const data = await response.json();
        setMonedas(data.data.currencies);
      } catch (err) {
        console.error('Error al cargar monedas:', err);
        setError('No se pudo cargar la lista de monedas.');
      }
    };
    fetchMonedas();
  }, [apiUrl, userToken]);

  // Manejar cambio de moneda
  const handleMonedaChange = async (e) => {
    const moneda = e.target.value;
    setSelectedMoneda(moneda);

    if (!moneda) {
      // Si no hay moneda seleccionada, reiniciar las redes y la dirección
      setRedes([]);
      setSelectedRed('');
      setCodigoCuenta('');
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/getNetworksByCurrency?currency=${moneda}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      const data = await response.json();
      setRedes(data.data.networks);
    } catch (err) {
      console.error('Error al cargar redes:', err);
      setError('No se pudo cargar la lista de redes.');
    }
  };

  // Manejar cambio de red
  const handleRedChange = async (e) => {
    const redCode = e.target.value;
    setSelectedRed(redCode);

    if (!redCode) {
      // Si no hay red seleccionada, limpiar la dirección de red
      setCodigoCuenta('');
      return;
    }

    try {
      const response = await fetch(
        `${apiUrl}/getWalletByCurrencyAndNetwork?user_identifier=${encodeURIComponent(userIdentifier)}&network_code=${encodeURIComponent(redCode)}`,
        {
          headers: { Authorization: `Bearer ${userToken}`, 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) throw new Error(`HTTP Error: ${response.status}`);

      const data = await response.json();
      if (data?.data?.wallet_address) {
        setCodigoCuenta(data.data.wallet_address);
      } else {
        throw new Error('No se encontró la dirección de la billetera.');
      }
    } catch (err) {
      console.error('Error al obtener la dirección:', err);
      setError('No se pudo obtener la dirección de la billetera.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedMoneda || !selectedRed || !codigoCuenta) {
      setError('Todos los campos son obligatorios.');
      return;
    }

    const dataToSend = {
      destiny_address: codigoCuenta,
      amount: 1, 
      user_identifier: userIdentifier,
      currency_symbol: selectedMoneda,
      network_code: selectedRed,
    };

    try {
      const response = await fetch(`${apiUrl}/withdrawal`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        setSuccess(true);
        setTimeout(() => navigate('/dashboard'), 2000); // Redirigir después de 2s
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error al realizar el depósito');
      }
    } catch (err) {
      console.error('Error al enviar la transacción:', err);
      setError('No se pudo completar la transacción.');
    }
  };

  return (
    <div className="App">
      <Aside />
      <main className="dashboard-main">
        <Navbar />
        <div className="dashboard-main-body">
          <div className="row gy-4">
            <div className="col-lg-9">
              <div className="card h-100 p-0 radius-12">
                <div className="card-header bg-base py-16 px-24">
                  <h6 className="mb-0">Formulario de Depósito</h6>
                </div>
                <div className="card-body p-24">
                  {error && <p className="text-danger">{error}</p>}
                  {success && <p className="text-success">Transacción realizada con éxito.</p>}
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label className="form-label">Moneda</label>
                      <select
                        className="form-select"
                        value={selectedMoneda}
                        onChange={handleMonedaChange}
                        style={{
                          outline: 'none',
                          boxShadow: 'none',
                        
                          boxShadow: 'none',
                          userSelect: 'none', 
                        }}
                      >
                        <option value="">Seleccione Moneda</option>
                        {monedas.map((moneda) => (
                          <option key={moneda.symbol} value={moneda.symbol}>
                            {moneda.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Red</label>
                      <select
                        className="form-select"
                        value={selectedRed}
                        onChange={handleRedChange}
                        style={{
                          outline: 'none',
                          boxShadow: 'none',
                        
                          boxShadow: 'none',
                          userSelect: 'none', 
                        }}
                      >
                        <option value="">Seleccione Red</option>
                        {redes.map((red) => (
                          <option key={red.code} value={red.code}>
                            {red.symbol} - {red.code}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Dirección de Red</label>
                      <input
                        type="text"
                        className="form-control"
                        value={codigoCuenta}
                        readOnly
                        style={{
                          backgroundColor: '#1e1e1e',
                          color: '#fff',
                          border: '1px solid #ced4da',
                          outline: 'none',
                          boxShadow: 'none',
                        }}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <WalletCard />
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default Deposito;
