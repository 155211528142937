import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

const RegisterCompanyModal = ({ show, onHide }) => {
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [files, setFiles] = useState({});
  const [error, setError] = useState('');

  const handleFileChange = (e) => {
    const newFiles = { ...files, [e.target.name]: e.target.files[0] };
    if (e.target.files[0].type !== 'application/pdf') {
      setError('Solo se permiten archivos PDF.');
    } else {
      setError('');
      setFiles(newFiles);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!companyName || !email) {
      setError('Por favor, completa todos los campos.');
      return;
    }

    if (Object.keys(files).length < 10) {
      setError('Por favor, sube los 10 documentos requeridos.');
      return;
    }

    alert('Formulario enviado');
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Registrar Empresa</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Nombre de la Empresa</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese el nombre de la empresa"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Ingrese el email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Label className="mt-3">Documentos (PDF)</Form.Label>
          <Row>
            {[
              'legal_rep_id',
              'curp',
              'legal_rep_fiscal_status',
              'legal_rep_house_proof',
              'constitutive_act',
              'legal_rep_powers',
              'public_registry',
              'company_fiscal_status',
              'company_address_proof',
              'company_operations_funds_act'
            ].map((field, index) => (
              <Col xs={12} md={6} className="mb-3" key={index}>
                <Form.Group>
                  <Form.Label style={{ fontSize: '0.85rem' }}>
                    {field.replace(/_/g, ' ').toUpperCase()}
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name={field}
                    onChange={handleFileChange}
                    className="form-control-sm"
                    style={{ fontSize: '0.85rem' }}
                    accept=".pdf"
                  />
                </Form.Group>
              </Col>
            ))}
          </Row>

          {error && <p className="text-danger">{error}</p>}

          <Button variant="dark" type="submit" className="w-100 mt-3">
            Enviar Registro
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RegisterCompanyModal;
