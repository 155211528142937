import React, { useState, useContext } from 'react';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../App';

const LoginAdmin = () => {
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { loginUser } = useContext(AuthContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch('https://api.bitfin.co:8081/adminLogin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ identifier, password }),
      });

      if (!response.ok) {
        throw new Error('Credenciales inválidas o error en el servidor.');
      }

      const data = await response.json();
      if (data?.data?.token) {
        loginUser(data.data.token, { name: identifier }, 'admin');
        setIdentifier('');
        setPassword('');
        navigate('/admin'); // Redirige al dashboard del admin
      } else {
        throw new Error('Token no encontrado en la respuesta.');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="auth d-flex align-items-center justify-content-center" style={{ height: '100vh', backgroundColor: '#1c1c1c' }}>
      <div className="text-center">
        <img src="../assets/images/logo.png" alt="Logo de la Empresa" style={{ maxWidth: '200px', marginBottom: '20px' }} />
        <h4 className="mb-12 text-white">Acceso Administrador</h4>
        <p className="mb-32 text-secondary-light text-lg text-white">Bienvenido a OTC</p>

        <form onSubmit={handleLogin} style={{ maxWidth: '300px', margin: '0 auto' }}>
          <div className="icon-field mb-16">
            <span className="icon top-50 translate-middle-y">
              <Icon icon="mdi:account" />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Email"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
              required
              disabled={loading}
            />
          </div>

          <div className="icon-field mb-16">
            <span className="icon top-50 translate-middle-y">
              <Icon icon="mdi:lock" />
            </span>
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              disabled={loading}
            />
          </div>

          {error && <p className="text-danger">{error}</p>}

          <button
            type="submit"
            className="btn btn-dark w-100"
            disabled={loading}
          >
            {loading ? 'Cargando...' : 'Iniciar Sesión'}
          </button>
        </form>
      </div>
    </section>
  );
};

export default LoginAdmin;
