import RegisterCompanyModal from './RegisterCompanyModal';
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Home.css';

function Home() {
  const [showModal, setShowModal] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [option, setOption] = useState('');
  const [volume, setVolume] = useState('');
  const [formError, setFormError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validar que todos los campos estén completos
    if (!company || !email || !option || !volume) {
      setFormError('Por favor, complete todos los campos y seleccione una opción.');
      return;
    }

    // Configurar el envío con EmailJS
    emailjs.send(
      'service_p72nt8k',           // Reemplaza con tu Service ID
      'template_5qd4ddw',           // Reemplaza con tu Template ID
      {
        company: company,           // Variables a enviar a la plantilla
        email: email,
        option: option,
        volume: volume
      },
      'zAWH-iAF6f33-0HDt'           // Reemplaza con tu Public Key
    )
    .then((response) => {
      setSuccessMessage('Enviado correctamente');
      setFormError('');
      setCompany('');
      setEmail('');
      setOption('');
      setVolume('');
      setTimeout(() => setSuccessMessage(''), 3000); // Eliminar el mensaje de éxito después de 3 segundos
    })
    .catch((error) => {
      setFormError('Hubo un error al enviar el formulario. Inténtelo nuevamente.');
      console.error('Error:', error);
    });
  };

  return (
    <div className="home">
      <header className="navbar">
        <div className="navbar-logo">
          <img src="/assets/images/logo.png" alt="Logo" style={{ height: '40px' }} />
        </div>
        <div className="menu-toggle" onClick={() => setMenuOpen(!menuOpen)}>
          <span className={`hamburger ${menuOpen ? 'open' : ''}`}></span>
        </div>
        <nav className={`navbar-links ${menuOpen ? 'open' : ''}`}>
          <a href="#trading" onClick={() => setMenuOpen(false)}>OTC TRADING</a>
          <a href="#why-us" onClick={() => setMenuOpen(false)}>¿POR QUÉ NOSOTROS?</a>
          <a href="#contact" onClick={() => setMenuOpen(false)}>CONTÁCTANOS</a>
          <a href="login" onClick={() => setMenuOpen(false)}>LOGIN</a>
          <a href="#!" onClick={() => { setShowModal(true); setMenuOpen(false); }}>REGISTRAR EMPRESA</a>
        </nav>
      </header>
      
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-overlay">
          <div className="hero-content">
            <div className="header-item">
              <img 
                src="../../assets/images/landing/hea-2.png" 
                alt="Icono OTC Trading" 
                className="icono-header" 
              />
              <div className="text-group">
                <h1>OTC Trading</h1>
                <p>Compra y vende Cryptomonedas de forma segura</p>
              </div>
            </div>
            <div className="header-item" id='trading'>
              <img 
                src="../../assets/images/landing/hea-1.png" 
                alt="Icono Transferencias Internacionales" 
                className="icono-header" 
              />
              <div className="text-group">
                <h2>Transferencias Internacionales</h2>
                <p>Envía y recibe remesas en segundos, utilizando la tecnología de Blockchain</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Segunda Sección */}
      <section className="help-section">
  <h2>OTC Trading</h2>
  <h3>Cómo te estamos ayudando</h3>
  <div className="cards-container">
    <div className="help-card">
      <img
        src="../../assets/images/landing/icon1_help.png"
        alt="Icono Enfoque a la medida"
        className="card-icon"
      />
      <h4>Enfoque a la medida</h4>
      <p>Nuestros traders asignados ejecutan sus órdenes las 24 horas, los 7 días de la semana...</p>
    </div>
    <div className="help-card">
      <img
        src="../../assets/images/landing/icon2_help.png"
        alt="Icono Ejecución impecable"
        className="card-icon"
      />
      <h4>Ejecución impecable</h4>
      <p>Aseguramos precios competitivos...</p>
    </div>
  </div>
</section>

{/* Sección "¿Por qué elegirnos?" */}
<div className="container-principal" id="why-us">
  <h2 className="section-title">¿Por qué elegirnos?</h2>
  <div className="contenedor-cuadros">
    <div className="cuadro">
      <img src="./assets/images/landing/section_3_1.png" alt="Altos límites transaccionales" />
      <h3>Altos límites transaccionales</h3>
      <p>Disfruta de la libertad de manejar grandes volúmenes de transacciones.</p>
    </div>
    <div className="cuadro">
      <img src="./assets/images/landing/section_3_2.png" alt="Conversión y disponibilidad en tiempo real" />
      <h3>Conversión y disponibilidad en tiempo real</h3>
      <p>Realiza conversiones al instante con nuestra plataforma, disponible las 24 horas del día.</p>
    </div>
    <div className="cuadro">
      <img src="./assets/images/landing/section_3_3.png" alt="Confianza y seguridad" />
      <h3>Confianza y seguridad</h3>
      <p>Nuestra prioridad es la protección de tus datos y transacciones.</p>
    </div>
    <div className="cuadro">
      <img src="./assets/images/landing/section_3_4.png" alt="Soporte 24/7" />
      <h3>Soporte 24/7</h3>
      <p>Estamos aquí para ti en todo momento. Nuestro equipo experto está listo para asistirte.</p>
    </div>
  </div>
</div>

      {/* Sección de Contacto */}
      <div className="contact-section" id="contact">
  <h2 className="contact-title">Contáctanos</h2>
  <form className="contact-form" onSubmit={handleSubmit}>
    <input
      type="text"
      placeholder="Compañía"
      required
      value={company}
      onChange={(e) => setCompany(e.target.value)}
    />
    <input
      type="email"
      placeholder="Correo electrónico"
      required
      value={email}
      onChange={(e) => setEmail(e.target.value)}
    />
    <div className="radio-group">
      <label>
        <input
          type="radio"
          name="option"
          value="crypto"
          checked={option === "crypto"}
          onChange={(e) => setOption(e.target.value)}
          style={{ marginRight: "10px", width: "18px", height: "18px" }}
        />
        Comprar y vender criptomonedas
      </label>
      <label>
        <input
          type="radio"
          name="option"
          value="transfers"
          checked={option === "transfers"}
          onChange={(e) => setOption(e.target.value)}
        />
        Realizar transferencias internacionales
      </label>
    </div>
    <input
      type="text"
      placeholder="Volumen estimado mensual"
      required
      value={volume}
      onChange={(e) => setVolume(e.target.value)}
    />
    {formError && <p className="form-error">{formError}</p>}
    {successMessage && <p className="form-success">{successMessage}</p>}
    <button type="submit">ENVIAR</button>
  </form>
</div>


      <footer className="footer">
  <div className="footer-container">
    <div className="footer-top">
      <div className="footer-logo">
        <img src="./assets/images/landing/footer.png" alt="Logo" />
      </div>
      <div className="footer-line"></div>
    </div>
    <div className="footer-bottom">
      <div className="footer-links">
        <a href="#terminos">Términos y Condiciones</a>
        <span>|</span>
        <a href="#aviso">Aviso Legal</a>
        <span>|</span>
        <a href="#privacidad">Políticas de Privacidad</a>
        <span>|</span>
        <a href="#preguntas">Preguntas Frecuentes</a>
      </div>
    </div>
  </div>
</footer>

      <RegisterCompanyModal show={showModal} onHide={() => setShowModal(false)} />
    </div>
  );
}

export default Home;
